/*
 * @Author: jianglinfeng
 * @objectDescription:
 * @Date: 2022-11-14 11:16:15
 * @LastEditors: mengjiajun
 * @LastEditTime: 2023-09-14 15:52:11
 * @FilePath: \yjc\src\utils\request.tsx
 * @Version:
 */

/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
// import 'react-router-dom';
import { message, notification } from 'antd';
import { codeMessage } from '@/constants/index';
import { extend, type ResponseError } from 'umi-request';

// import history from '@/utils/history';

/**
 * @TODO 异常处理程序
 */
const errorHandler = (error: ResponseError) => {
  const { response } = error;

  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    notification.error({
      message: `请求错误 ${status}: ${url}`,
      description: errorText,
    });
  } else if (!response) {
    notification.error({
      description: '您的网络发生异常，无法连接服务器',
      message: '网络异常',
    });
  }

  return response;
};

/**
 * @TODO 配置request请求时的默认参数
 */
const request = extend({
  prefix: import.meta.env.VITE_URL,
  // 默认错误处理
  errorHandler,
  // 开启CORS跨域
  crossOrigin: true,
  // 默认请求是否带上cookie
  // credentials: 'include',
});

/**
 * @TODO 请求拦截
 */
request.interceptors.request.use((url, options) => {
  let newOptions = { ...options };
  const {
    state: { token },
  } = JSON.parse(sessionStorage.getItem('token-storage') as string);

  newOptions.headers = {
    ...newOptions.headers,
    Authorization: `Bearer ${token}`,
  };

  // 如果是导出类接口加上类型
  if (
    url.includes('export') ||
    url.includes('download') ||
    url.includes('Download')
  ) {
    newOptions.responseType = 'blob';
  }
  return {
    url: `${url}`,
    options: { ...newOptions },
  };
});

/**
 * @TODO 响应拦截
 */
request.interceptors.response.use(async (response) => {
  let res = null;

  // 导出类接口，直接返回文件流
  if (
    response.url.includes('export') ||
    response.url.includes('download') ||
    response.url.includes('Download')
  ) {
    res = response;
  } else {
    // 克隆响应对象做解析处理
    // 这里的res就是我们请求到的数
    res = await response.clone().json();
    const { code, msg } = res;
    if (code === 401) {
      message.info('请重新登录');
      sessionStorage.setItem('token-storage', '');
      window.location.reload();
    }

    if (code !== 200 && code !== 401) {
      notification.error({
        message: '请求错误',
        description: `${code}: ${msg}`,
      });
      // 在处理结果时判断res是否有值即可
      return;
    }
  }
  return res;
});

export default request;
