/*
 * @Author: jianglinfeng
 * @objectDescription: 库存
 * @Date: 2022-11-18 15:29:57
 * @LastEditors: LGX
 * @LastEditTime: 2024-01-23 14:08:30
 * @FilePath: \ejc-resource-management-webui\src\api\repertory.ts
 * @Version:
 */
import { TBreakageParams } from '@/pages/EarlyWarning/type';
import request from '@/utils/request';

export enum API {
  //获取库存
  GET_CATEGORY_LIST_LIST_MATERIAL = '/categoryList/listMaterial',
  //获取库存(存量库存)
  IN_STOCK_LIST = '/inventory/info/listStock',
  //获取库存(实物库存)
  MATTER_LIST = '/inventory/info/listMatter',
  // 其他入库-物资列表
  MATERIAL_LIST = '/catalogDetails/list',
  // 添加物资到领用单(购物车)
  ADD_MATERIAL_TO_RECEIVE = '/selectedMaterial/add',
  // 添加领用篮前校验是否有过期物资
  GET_ADD_MATERIAL_CHECK_EXPIRATION = '/selectedMaterial/verification',
  // 耗材入库
  POST_CATEGORY_LIST_LIST_WAREHOUSING = '/inventory/consumable/inStore',
  // 实物库存入库
  PHYSICAL_INVENTORY_INSTOCK = '/inventory/rm/inStore',
  // 实物库存出库
  PHYSICAL_INVENTORY_OUTSTOCK = '/inventory/rm/outStore',
  //明细
  GET_CATEGORY_LIST_OTHERWAREHOUSING = '/purchaseOrderWait/queryOrderByDetailsStoreIn',
  //采购单数据
  GET_CATEGORY_LIST_PURCHASE_CHOICE = '/purchaseOrderWait/queryOrderByInfoStoreIn',
  // 其他出库-领用申请单明细
  GET_CATECORY_LIST_LSSUE_CHOICE = '/categoryList/otherIssueChoice/',
  // 获取仓库、货架位置信息数据
  GET_WAREhOUSE_LOCATION_DATA_LIST = '/baseStore/listTreeStore',
  // 获取领用申请单（购物车）中的物资数量
  GET_RECEIVE_MATERIAL_COUNT = '/selectedMaterial/selectCount',
  // 获取领用申请单（购物车）中的物资列表
  GET_RECEIVE_MATERIAL_LIST = '/selectedMaterial/queryAll',
  // 删除领用申请单（购物车）中的物资
  DELETE_RECEIVE_MATERIAL = '/selectedMaterial/batchDeleteSelected',
  // 获取当前库存总数
  MATERIAL_IN_STOCK_TOTAL = '/inventory/info/selectCountMaterial',
  // 提交领用申请
  SUBMIT_RECEIVE_APPLICATION = '/receiveBill/add',
  // 领用申请单列表
  RECEIVE_ORDERS_LIST = '/receiveBill/queryAll',
  // 获取当前申领单数量
  RECEIVE_ORDERS_COUNT = '/receiveBill/countByEntity',
  // 获取当前采购订单数量
  PURCHASE_ORDERS_COUNT = '/purchaseOrder/countByEntity',
  // ...
  RECEIVE_ORDER_DETAILS = '/receiveBillDetail/queryAll',
  // 出库
  OUT_WAREHOUSE = '/inventory/consumable/outStore',
  // 存量库存物资详情查询
  MATERIAL_BASIC_INFO = '/inventory/info/queryStockInfo',
  // 实物库存物资详情查询
  MATTER_BASIC_INFO = '/inventory/info/queryMatterInfo',
  // 批次信息table查询
  MATERIAL_BATCH_INFO = '/inventory/info/queryBatchInfoList',
  // 出入记录table查询
  MATERIAL_IMPORT_OUT_INFO = '/inventory/info/querySerialList',
  // 物资目录导出
  MATERIAL_EXPORT_INFO = '/inventory/info/exportStock',
  // 实物库存数据导出
  EXPORT_MATTER_STOCK_INFO = '/inventory/info/exportMatter',
  // 20230323 对库存模块的修改，新增新的获取库存列表接口
  // 该接口不再区分是存量库存或是实物库存，通过参数进行区分
  QUERY_INVENTORY_LIST = '/inventory/info/list',
  // 20230329 - 新的通用入库接口，合并之前的存量入库和实物入库
  INVENTORY_UNIVERSAL_IN_STOCK = '/inventory/inStore',
  // 20230329 - 新的通用出接口，合并之前的存量和实物
  INVENTORY_UNIVERSAL_OUT_STOCK = '/inventory/outStore',

  // 报损出库
  POST_BREAKAGE_OUT_STOCK = '/reportingLosses/add',
}

// 根据字段名称模糊查询
type CategoryListType = {
  categoryId?: string;
  stockId?: string;
  materialNo?: string;
  categoryName?: string;
  cas?: string;
  enName?: string;
  alias?: string;
  pageSize?: number;
  current?: number;
  keyword?: string;
  [key: string]: any;
};
export const getCategoryList = async (params: CategoryListType) =>
  request.get(API.GET_CATEGORY_LIST_LIST_MATERIAL, { params });

// 根据字段名称模糊查询
// type InStockListType = {
//   currentPage: number;
//   pageSize: number;
//   param?: {
//     categoryId?: number;
//     stockId?: string;
//     materialCode?: string;
//     materialName?: string;
//     cas?: string;
//     enName?: string;
//     aliasName?: string;
//   };
// };

/**
 * @description 获取库存明细列表
 * @param params
 * @returns
 */
export const getInStockList = async (
  params: any = { currentPage: 1, pageSize: 10 }
) => request.post(API.IN_STOCK_LIST, { data: params });

/**
 * @description 其他入库- 获取物资列表
 * @param params
 * @returns
 */
export const getMaterialList = async (params: Record<string, unknown> = {}) =>
  request.get(API.MATERIAL_LIST, { params });

/**
 * @description 处理双击物资添加到领用单操作
 * @param
 * @returns void
 */
export const addMaterialToReceive = async (params: { skuIds: string }) =>
  request.post(API.ADD_MATERIAL_TO_RECEIVE, { data: params });

/**
 * @description 添加领用篮前校验是否有过期物资
 * @param
 * @returns void
 */
export const addMaterialCheckExpiration = async (params: {
  skuId: string,
  materialId: number,
  storeId: number,
}) =>
  request.get(API.GET_ADD_MATERIAL_CHECK_EXPIRATION, { params });

//入库
export type WarehousingType = {
  putInTime: string;
  receivingClerk: string;
  supplier: string;
  warehousingMaterialBo: string;
  [key: string]: any;
};
export const postWarehousing = async (params: Record<string, any>) =>
  request.post(API.POST_CATEGORY_LIST_LIST_WAREHOUSING, { data: params });

//物资明细
export type WarehousingListType = {
  [key: string]: any;
};
export const getWarehousingList = async (params: any) =>
  request.get(API.GET_CATEGORY_LIST_OTHERWAREHOUSING, { params });

//采购单数据
export type PurchaseChoiceType = {
  billSubmit?: string;
  billNo?: string;
  billStartTime?: string;
  billEndTime?: string;
  pageSize?: number;
  current?: number;
};
export const getPurchaseChoice = async (params: any) =>
  request.get(API.GET_CATEGORY_LIST_PURCHASE_CHOICE, { params });

// 出库-其他出库领用申请明细
export const getLssueChoice = async (collectId: number) =>
  request.get(API.GET_CATECORY_LIST_LSSUE_CHOICE + collectId);

// 获取仓库、货架等位置信息数据列表
export const wareHouseLocationDataList = async (
  params?: Record<string, unknown>
) => request.get(API.GET_WAREhOUSE_LOCATION_DATA_LIST, { params });

// 获取领用申请单中的物资数量
export const recevieMaterialCount = async (params?: Record<string, any>) =>
  request.get(API.GET_RECEIVE_MATERIAL_COUNT, { params });

// 获取领用申请单中的物资列表
export const receiveMaterialList = async (params?: {
  currentPage: 1;
  pageSize: 10;
}) => request.get(API.GET_RECEIVE_MATERIAL_LIST, { params });

// 删除领用申请单中的物资
export const deleteReceiveMaterial = async (ids: string) =>
  request.post(API.DELETE_RECEIVE_MATERIAL, { data: { ids } });

// 获取当前库存总数
export const getMaterialInStockTotal = async (params?: {
  categoryId: number;
}) =>
  request.get<{ code: number; data: any; msg: string }>(
    API.MATERIAL_IN_STOCK_TOTAL,
    { params }
  );

// 提交领用申请
export const submitReceiveApplication = async (params: any) =>
  request.post(API.SUBMIT_RECEIVE_APPLICATION, { data: params });

// 领用出库单列表
export const getReceiveOrdersListt = async (params: any) =>
  request.get(API.RECEIVE_ORDERS_LIST, { params });

// 获取领用申请单数量
export const queryReceiveOrdersCount = async (params?: Record<string, any>) =>
  request.get(API.RECEIVE_ORDERS_COUNT, { params });

// 获取采购订单数量
export const queryPurchaseOrdersCount = async (params?: Record<string, any>) =>
  request.get(API.PURCHASE_ORDERS_COUNT, { params });

// 点击列表获取申领单详情列表
export const getReceiveOrderDetails = async (params: any) =>
  request.get(API.RECEIVE_ORDER_DETAILS, { params });

// 存量物资出库
export const handleOutWarehouse = async (data: any) =>
  request.post(API.OUT_WAREHOUSE, { data: data });

// 点击物资编号查看物资信息 - 物品信息 (存量库存)
export const getReceiveBasicInfo = async (id: string) =>
  request.get(`${API.MATERIAL_BASIC_INFO}/${id}`);

// 点击物资编号查看物资信息 - 物品信息 (实物库存)
export const getMatterBasicInfo = async (id: string) =>
  request.get(`${API.MATTER_BASIC_INFO}/${id}`);

// 点击物资编号查看物资信息 - 批次信息
export type batchType = {
  materialId: number | string; //物资id
  importYear: string; //入库年份
};
export const getReceiveBatchInfo = async (params: batchType) =>
  request.get(API.MATERIAL_BATCH_INFO, { params });

// 点击物资编号查看物资信息 - 出入记录
export type importOutType = {
  materialId: number | string; //物资id
  year: string; //入库年份
};
export const getReceiveImportOutInfo = async (params: importOutType) =>
  request.get(API.MATERIAL_IMPORT_OUT_INFO, { params });

// 物资目录 - 导出
export type importType = {
  categoryId?: number;
  stockId?: string;
  materialCode?: string;
  materialName?: string;
  cas?: string;
  enName?: string;
  aliasName?: string;
};
export const exportMaterial = async (params: importType) =>
  request.get(API.MATERIAL_EXPORT_INFO, { params });

/**
 * @description 获取实物库存明细列表
 * @param params
 * @returns
 */
export const getMatterList = async (
  params: any = { currentPage: 1, pageSize: 10 }
) => request.post(API.MATTER_LIST, { data: params });

/**
 * @description 实物库存入库
 */
export const handlePhysicalInventoryInStock = async (
  params: Record<string, any>
) => request.post(API.PHYSICAL_INVENTORY_INSTOCK, { data: params });

/**
 * @description 实物库存出库
 */
export const handlePhysicalInventoryOutStock = async (
  params: Record<string, any>
) => request.post(API.PHYSICAL_INVENTORY_OUTSTOCK, { data: params });

/**
 * @description 实物库存导出
 */
export const exportMatterStockInfo = async (params?: Record<string, any>) =>
  request.get(API.EXPORT_MATTER_STOCK_INFO, { params });

/**
 * @description 新增的获取物资库存接口，该接口不再区分是存量或实物，通过参数区分
 */
export const queryInventoryList = async (params: Record<string, any>) =>
  request.post(API.QUERY_INVENTORY_LIST, { data: params });

/**
 * @description 新的通用入库接口
 */
export const inventoryUniversalInStock = async (params: Record<string, any>) =>
  request.post(API.INVENTORY_UNIVERSAL_IN_STOCK, { data: params });

/**
 * @description 新的通用出库接口
 */
export const inventoryUniversalOutStock = async (params: Record<string, any>) =>
  request.post(API.INVENTORY_UNIVERSAL_OUT_STOCK, { data: params });

/**
 * @interface 报损出库参数
 */
interface IBreakageOutStockParams {
  reportingLossesDto: ReportingLossesDto;
  lossesDetailDtoList: TBreakageParams[];
}
interface ReportingLossesDto {
  lossesTime: string;
  operator: string;
  operatorId: number;
  recipientName: string;
  recipientId: number;
  reason: string;
  storeId: number;
}

/**
 * @description 报损出库
 */
export const queryBreakageOutStock = async (data: IBreakageOutStockParams) =>
  request.post(API.POST_BREAKAGE_OUT_STOCK, { data });
