/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { ConfigProvider, message, theme } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { AllRouteMappings, InitialRoutes, RouterGurad } from '@/routes';
import 'dayjs/locale/zh-cn';
import { cloneDeep } from 'lodash';
import Loading from '@/pages/Loading';
import { queryPermissionRouter } from './api/auth';
import { codeDefinition } from './constants';
import {
  usePermissionRouterStore,
  useThemeStore,
  useTokenStore,
} from './store';

/**
 * @TODO 生成react-router依赖的route数据
 */
export const getInitialRoutes = (route: any): any => {
  const arr = route.filter((item: any) => item.path !== '/home');
  const data: any = arr.map(() => ({
    children: [],
  }));
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    let val = AllRouteMappings.find((route) => route.path === item.path);
    if (val) {
      data[i] = { ...data[i], ...val };
      if (item.children && item.children.length) {
        data[i].children = [...getInitialRoutes(item.children)];
      } else {
        delete data[i].children;
      }
    }
  }
  return [...data];
};

const App: React.FC = () => {
  // 当前默认主题
  const { prefix } = useThemeStore();

  const { token } = useTokenStore();

  const location = useLocation();

  const { setAllRouter, setRouteStructure, routeStructure } =
    usePermissionRouterStore();

  /**
   * @TODO 获取用户路由
   */
  const getUserRouter = async () => {
    try {
      const { code, data, msg } = await queryPermissionRouter();
      if (code === codeDefinition.QUERY_SUCCESS) {
        // console.log(data);

        setAllRouter(data);

        // 生成react-router依赖的route数据
        const route = cloneDeep(InitialRoutes);
        route[0].children = [...route[0].children, ...getInitialRoutes(data)];
        // console.log(route);

        setRouteStructure(route);
      } else {
        message.error(msg);
      }
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  };

  // useEffect(() => {
  //   console.log(location.pathname);

  //   setOriginUrl(window.location.origin);
  // }, []);

  useEffect(() => {
    !['/auth/singin', '/auth/singon'].includes(location.pathname) &&
      token &&
      getUserRouter();
  }, []);

  return (
    <ConfigProvider
      locale={zhCN}
      theme={{
        algorithm: theme.defaultAlgorithm,
      }}
    >
      <React.Suspense fallback={<Loading />}>
        <div className={`App ${prefix} h-[100vh]`}>
          {RouterGurad(routeStructure)}
        </div>
      </React.Suspense>
    </ConfigProvider>
  );
};

export default App;
