/**
 * @description 权限校验相关的 API 请求定义，包含用户登录、登出等
 */
import request from '@/utils/request';

enum API {
  UserInfo = '/api/getUser',
  // 获取权限路由
  GET_PERMISSION_ROUTER = '/getRouters',
}

export const getUserInfo = () => request.get(API.UserInfo, {});

/**
 * @description 获取权限路由
 */
export const queryPermissionRouter = async () =>
  request.get(API.GET_PERMISSION_ROUTER);
