/* eslint-disable @typescript-eslint/no-unused-vars */
import { lazy, useEffect } from 'react';
import {
  IndexRouteObject,
  Location,
  NavigateFunction,
  NonIndexRouteObject,
  useLocation,
  useNavigate,
  useRoutes,
} from 'react-router-dom';
import { APP_TITLE } from '@/constants';

const PageLayout = lazy(() => import('@/layouts/PageLayout'));

// 首页
const Home = lazy(() => import('@/pages/Home'));
// 任务
// 任务 - 待处理
const Approval = lazy(() => import('@/pages/Approval'));
// 任务 - 已处理
const ApprovalProcessed = lazy(
  () => import('@/pages/Approval/ApprovalProcessed')
);
// 任务 - 已发起
const ApprovalApply = lazy(() => import('@/pages/Approval/ApprovalApply'));
// 采购
// 采购 - 采购申请
const PurchaseRequirements = lazy(
  () => import('@/pages/Purchase/PurchaseHome')
);
// 采购 - 需求订单
const RequirementOrders = lazy(
  () => import('@/pages/Purchase/PurchaseOrdersHome')
);
// 资料
// 资料 - 物资目录
const Material = lazy(() => import('@/pages/Materials/MaterialCatalogue'));
// 资料 - 仓库管理
const WarehouseManager = lazy(
  () => import('@/pages/Materials/WarehouseManager')
);
// 资料 - 计量单位
const UnitMeasurement = lazy(() => import('@/pages/Materials/UnitMeasurement'));
// 资料 - 规格管理
const SpecificationManagement = lazy(
  () => import('@/pages/Materials/SpecificationManagement')
);
// 资料 - 品牌管理
const BrandManagement = lazy(() => import('@/pages/Materials/BrandManagement'));
// 资料 - 供应商目录
const Supplier = lazy(() => import('@/pages/Materials/Supplier'));
// 库存
// 库存 - 库存明细
const InventoryWrapper = lazy(() => import('@/pages/Inventory'));
// 库存 - 库存汇总
const InventorySummary = lazy(() => import('@/pages/InventorySummary'));
// 库存 - 库存流水
const InventoryFlow = lazy(() => import('@/pages/InventoryFlow'));
// 库存 - 预警
const EarlyWarning = lazy(() => import('@/pages/EarlyWarning'));
// 统计
// 统计 - 采购统计
const PurchasingStatistics = lazy(
  () => import('@/pages/Statistics/PurchasingStatistics')
);
// 统计 - 领用统计
const RequisitionStatistics = lazy(
  () => import('@/pages/Statistics/RequisitionStatistics')
);
// 财务
// 财务 - 付款管理
const PayManagement = lazy(() => import('@/pages/Finance/PayManagement'));
// 财务 - 档案管理
const RecordManagement = lazy(() => import('@/pages/Finance/RecordManagement'));
// 我的
// 我的 - 概况
const Overview = lazy(() => import('@/pages/Profile/Overview'));
// 我的 - 消息提醒
const MessageReminder = lazy(() => import('@/pages/Profile/MessageReminder'));
// 我的 - 个人消息设置
const PersonalMsgSettings = lazy(
  () => import('@/pages/Profile/PersonalMsgSettings')
);
// 系统
// 系统 - 组织
// 系统 - 组织  - 部门管理
const DepartmentManagement = lazy(
  () => import('@/pages/Settings/Organize/DepartmentManagement')
);
// 系统 - 组织  - 人员管理
const PersonnelManagement = lazy(
  () => import('@/pages/Settings/Organize/PersonnelManagement')
);
// 系统 - 组织  - 角色管理
const RoleManagement = lazy(
  () => import('@/pages/Settings/Organize/RoleManagement')
);
// 系统 - 组织 - 菜单管理
const MenuManagement = lazy(
  () => import('@/pages/Settings/Organize/MenuManagement')
);
// 系统 - 流程
// 系统 - 流程  - 流程规则
const ProcessRules = lazy(
  () => import('@/pages/Settings/Workflow/ProcessRules')
);
// 系统 - 流程  - 流程匹配
const ProcessMatching = lazy(
  () => import('@/pages/Settings/Workflow/ProcessMatching')
);
// 系统 - 个性化
// 系统 - 个性化 - 编号管理
const NumberManagement = lazy(
  () => import('@/pages/Settings/Personalise/NumberManagement')
);
// 系统 - 个性化 - 模板管理
const TemplateManagement = lazy(
  () => import('@/pages/Settings/Personalise/TemplateManagement')
);
// 系统 - 个性化 - 数据字典
const DataDictionary = lazy(
  () => import('@/pages/Settings/Personalise/DataDictionary')
);
// 系统 - 个性化 - 属性库管理
const ProperylibraryManangement = lazy(
  () => import('@/pages/Settings/Personalise/ProperylibraryManangement')
);
// 系统 - 个性化 - 参数设置
const ParamConfig = lazy(
  () => import('@/pages/Settings/Personalise/ParamConfig')
);
// 系统 - 初始化
// 系统 - 初始化 - 新手引导
const NoviceGuidance = lazy(
  () => import('@/pages/Settings/Initialization/NoviceGuidance')
);
// 系统 - 初始化 - 期初库存
const BeginningInventory = lazy(
  () => import('@/pages/Settings/Initialization/BeginningInventory')
);
// 登录
const Singin = lazy(() => import('@/pages/Login/Singin'));



const CommitPriceCompare = lazy(() => import('@/pages/commitPriceCompare/commitPriceCompare'));



// 登录加载
const SingOn = lazy(() => import('@/pages/Login/SignOn'));
// 404
const NotFound = lazy(() => import('@/pages/404'));
// 403
const NotAuth = lazy(() => import('@/pages/403'));
// 文件预览
const EFileViewWrapper = lazy(() => import('@/components/EFileView'));

const WorkflowContainer = lazy(() => import('@/pages/Settings/Workflow'));

const InStock = lazy(() => import('@/pages/Reagent/InStock'));

const AuthLayout = lazy(() => import('@/layouts/AuthLayout'));

type CustomRoutePerpies = {
  title?: string;
};

type TIndexRouteObject = IndexRouteObject & CustomRoutePerpies;
type TNonIndexRouteObject = NonIndexRouteObject &
  CustomRoutePerpies & {
    title?: string | React.ReactNode;
    children?: CustomRouteObject[];
  };

type CustomRouteObject = TIndexRouteObject | TNonIndexRouteObject;

export const InitialRoutes: any[] = [
  // 页面路由策略
  {
    element: <PageLayout />,
    children: [
      // 首页
      {
        path: '/',
        element: <Home />,
        title: '首页',
      },
      // 没有访问权限的路由 403
      {
        path: '/notAuth',
        element: <NotAuth />,
        title: '没有访问权限',
      },
      // 未匹配上的路由 404
      {
        path: '/*',
        element: <NotFound />,
        title: '未知页面',
      },

    ],
  },
  // 其他不需要 PageLayout 控制的页面
  {
    path: '/auth',
    // element: <AuthLayout />,
    // layout: false,
    children: [
      {
        path: '/auth/singin',
        element: <Singin />,
        auth: true,
        title: '登录',
      },
      // 易检测登录加载页 SingOn
      {
        path: '/auth/singon',
        element: <SingOn />,
        auth: true,
        title: '登录加载',
      },
      //比价询价
      {
        path: '/auth/commitPriceCompare',
        element: <CommitPriceCompare />,
        auth: true,
        title: '比价询价',
      },
    ],
  },



  // 文件预览
  {
    path: '/file-viewer',
    element: <EFileViewWrapper />,
    title: '文件预览',
  },
];

export const AllRouteMappings = [
  { title: '首页', path: '/', element: <Home />, completePath: '/' },
  { title: '任务', path: '/approval' },
  {
    title: '待处理',
    path: 'approvalPending',
    element: <Approval />,
    completePath: '/approval/approvalPending',
  },
  {
    title: '已处理',
    path: 'approvalProcessed',
    element: <ApprovalProcessed />,
    completePath: '/approval/approvalProcessed',
  },
  {
    title: '已发起',
    path: 'approvalApply',
    element: <ApprovalApply />,
    completePath: '/approval/approvalApply',
  },
  { title: '采购', path: '/purchase' },
  {
    title: '采购申请',
    path: 'purchaseRequirements',
    element: <PurchaseRequirements />,
    completePath: '/purchase/purchaseRequirements',
  },
  {
    title: '需求订单',
    path: 'purchaseOrders',
    element: <RequirementOrders />,
    completePath: '/purchase/purchaseOrders',
  },
  { title: '资料', path: '/materials' },
  {
    title: '物资目录',
    path: 'materialcatalogue',
    element: <Material />,
    completePath: '/materials/materialcatalogue',
  },
  {
    title: '仓库管理',
    path: 'warehouseManager',
    element: <WarehouseManager />,
    completePath: '/materials/warehouseManager',
  },
  {
    title: '计量单位',
    path: 'unitMeasurement',
    element: <UnitMeasurement />,
    completePath: '/materials/unitMeasurement',
  },
  {
    title: '规格管理',
    path: 'specificationManagement',
    element: <SpecificationManagement />,
    completePath: '/materials/specificationManagement',
  },
  {
    title: '品牌管理',
    path: 'brandManagement',
    element: <BrandManagement />,
    completePath: '/materials/brandManagement',
  },
  {
    title: '供应商目录',
    path: 'supplierManagement',
    element: <Supplier />,
    completePath: '/materials/supplierManagement',
  },
  {
    title: '库存',
    path: '/inventory',
    element: <InventoryWrapper />,
    completePath: '/inventory',
  },
  {
    title: '库存汇总',
    path: '/inventorySummary',
    element: <InventorySummary />,
    completePath: '/inventorySummary',
  },
  {
    title: '库存流水',
    path: '/inventoryFlow',
    element: <InventoryFlow />,
    completePath: '/inventoryFlow',
  },
  {
    title: '预警',
    path: '/earlyWarning',
    element: <EarlyWarning />,
    completePath: '/earlyWarning',
  },
  { title: '我的', path: '/profile' },
  {
    title: '概况',
    path: 'overview',
    element: <Overview />,
    completePath: '/profile/overview',
  },
  {
    title: '消息提醒',
    path: 'messageReminder',
    element: <MessageReminder />,
    completePath: '/profile/messageReminder',
  },
  {
    title: '个人消息设置',
    path: 'personalMsgSettings',
    element: <PersonalMsgSettings />,
    completePath: '/profile/personalMsgSettings',
  },
  { title: '系统', path: '/settings' },
  { title: '组织', path: 'organize', completePath: '/settings/organize' },
  {
    title: '部门管理',
    path: 'departmentManagement',
    element: <DepartmentManagement />,
    completePath: '/settings/organize/departmentManagement',
  },
  {
    title: '人员管理',
    path: 'personnelManagement',
    element: <PersonnelManagement />,
    completePath: '/settings/organize/personnelManagement',
  },
  {
    title: '角色管理',
    path: 'roleManagement',
    element: <RoleManagement />,
    completePath: '/settings/organize/roleManagement',
  },
  {
    title: '菜单管理',
    path: 'menuManagement',
    element: <MenuManagement />,
    completePath: '/settings/organize/menuManagement',
  },
  { title: '流程', path: 'workflow', completePath: '/settings/workflow' },
  {
    title: '流程规则',
    path: 'processRules',
    element: <ProcessRules />,
    completePath: '/settings/workflow/processRules',
  },
  {
    title: '流程匹配',
    path: 'processMatching',
    element: <ProcessMatching />,
    completePath: '/settings/workflow/processMatching',
  },
  {
    title: '个性化',
    path: 'personalise',
    completePath: '/settings/personalise',
  },
  {
    title: '编号管理',
    path: 'numberManagement',
    element: <NumberManagement />,
    completePath: '/settings/personalise/numberManagement',
  },
  {
    title: '模板管理',
    path: 'templateManagement',
    element: <TemplateManagement />,
    completePath: '/settings/personalise/templateManagement',
  },
  {
    title: '数据字典',
    path: 'dataDictionary',
    element: <DataDictionary />,
    completePath: '/settings/personalise/dataDictionary',
  },
  {
    title: '属性库管理',
    path: 'properylibraryManangement',
    element: <ProperylibraryManangement />,
    completePath: '/settings/personalise/properylibraryManangement',
  },

  {
    title: '参数设置',
    path: 'paramConfig',
    element: <ParamConfig />,
    completePath: '/settings/personalise/paramConfig',
  },
  {
    title: '初始化',
    path: 'initialization',
    completePath: '/settings/initialization',
  },
  {
    title: '新手引导',
    path: 'noviceGuidance',
    element: <NoviceGuidance />,
    completePath: '/settings/initialization/noviceGuidance',
  },
  {
    title: '期初库存',
    path: 'beginningInventory',
    element: <BeginningInventory />,
    completePath: '/settings/initialization/beginningInventory',
  },
  { title: '未知页面', path: '/*', element: <NotFound /> },
  { title: '没有访问权限', path: '/notAuth', element: <NotAuth /> },
  { title: '登录', path: '/auth/singin', element: <Singin /> },
  { title: '比价询价',
  path: '/auth/commitPriceCompare',
   element: <CommitPriceCompare /> },
  { title: '登录加载', path: '/auth/singon', element: <SingOn /> },
  { title: '统计', path: '/statistics' },
  {
    title: '采购统计',
    path: 'purchasingStatistics',
    completePath: '/statistics/purchasingStatistics',
    element: <PurchasingStatistics />,
  },
  {
    title: '领用统计',
    path: 'requisitionStatistics',
    completePath: '/statistics/requisitionStatistics',
    element: <RequisitionStatistics />,
  },
  { title: '财务', path: '/finance' },
  {
    title: '付款管理',
    path: 'payManagement',
    completePath: '/finance/payManagement',
    element: <PayManagement />,
  },
  {
    title: '档案管理',
    path: 'recordManagement',
    completePath: '/finance/recordManagement',
    element: <RecordManagement />,
  },
];

/**
 * @description 递归查询对应的路由
 * @param path
 * @param routes
 * @returns
 */
export function searchRouteDetail(
  path: string,
  routes: CustomRouteObject[]
): CustomRouteObject | null {
  for (let item of routes) {
    if (item.path === path) return item;
    if (item.children) {
      return searchRouteDetail(path, item.children);
    }
  }
  return null;
}

/**
 * @description 全局路由守卫
 * @param location
 * @param navigate
 * @param routes
 * @returns
 */
function Guard(
  location: Location,
  navigate: NavigateFunction,
  routes: CustomRouteObject[]
) {
  const { pathname } = location;

  // 判断是否有token
  const {
    state: { token },
  } = JSON.parse(sessionStorage.getItem('token-storage') as string);

  // 没有token重定向至登录页
  if (!token) {
    if (![`/auth/singon`, `/auth/singin`, `/auth/commitPriceCompare`].includes(pathname)) {
      navigate(`/auth/singin`);
    }
  }

  //找到对应的路由信息
  const routeDetail = searchRouteDetail(pathname, routes);

  //没有找到路由，跳转404
  if (!routeDetail) {
    return false;
  }

  /**
   * @TODO 动态自定义窗口标题
   */
  if (routeDetail.title) {
    document.title = `${APP_TITLE} - ${routeDetail.title}`;
  } else {
    document.title = `${APP_TITLE}`;
  }

  return true;
}

export const RouterGurad = (routes: CustomRouteObject[]) => {
  const location = useLocation();

    // // debugger
    // if (location.pathname.includes("commitPriceCompare")) {
    //   return
    // }
  const navigate = useNavigate();
  useEffect(() => {
    Guard(location, navigate, routes);
  }, [location, navigate, routes]);
  const Route = useRoutes(routes);
  return Route;
};
